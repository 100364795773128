import axios from "axios";
import { API_SERVER_URL } from "../actions/actionUrl";

// const viewLocalSetup = (id) => {
//   let today = new Date().toLocaleDateString();
//   let setupDate = localStorage.getItem(`${id}setupDate`);
//   console.log(setupDate === today)
//   // console.log(setupDate === null || setupDate !== today)
//   if (setupDate === null || setupDate !== today) {
//     localStorage.setItem(`${id}setupDate`, today);
//     localStorage.setItem(`${id}setup`, 'true');
//   } else {
//     localStorage.setItem(`${id}setup`, 'false');
//   }
// };

const viewLocalSetup = (id) => {
  let today = new Date().toLocaleDateString();
  let viewSet = JSON.parse(localStorage.getItem(`viewSet`));
  if (!viewSet) {
    viewSet = [];
  }
  
  let item = viewSet.find((item) => item.id === id);
  if (!item) {
    item = {
      id: id,
      setupDate: null,
      setup: 'false',
    };
    viewSet.push(item);
  }
  if (item.setupDate === null || item.setupDate !== today) {
    item.setupDate = today;
    item.setup = 'true';
  } else {
    item.setup = 'false';
  }
  localStorage.setItem(`viewSet`, JSON.stringify(viewSet));
};

async function increaseView(id, type) {
  let viewSet = JSON.parse(localStorage.getItem(`viewSet`));
  let item = viewSet.find((item) => item.id === id);
  if (item.setup === 'true') {
    if (type === "competition") { await axios.put( `${API_SERVER_URL}/api/competition/increase-view/${id}`); }
    else if (type === "community") { await axios.put( `${API_SERVER_URL}/api/community/increase-view/${id}`); }
  }
}

const setupOptimize = () => {
  let viewSet = JSON.parse(localStorage.getItem(`viewSet`));
  if (!viewSet) {
    viewSet = [];
  }
  let today = new Date().toLocaleDateString();
  // let today = new Date().toISOString();
  viewSet = viewSet.filter((item) => item.setupDate === today);
  localStorage.setItem(`viewSet`, JSON.stringify(viewSet));
};

const dailyLogout = () => {
  let today = new Date().toLocaleDateString();
  // let today = new Date().toISOString();
  let logoutDate = localStorage.getItem(`logoutDate`);
  if (logoutDate === null || logoutDate !== today) {
    localStorage.setItem(`logoutDate`, today);
    localStorage.removeItem(`userInfo`);

    //logout
    window.location.reload();
  }
}

export {
  viewLocalSetup,
  increaseView,
  setupOptimize,
  dailyLogout,
};